<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="card-header">
            <h2 class="card-title font-weight-bold">Danh Sách Bác Sĩ</h2>
          </div>
          <!-- <div class="card-header">
            <h3 class="card-title">Danh Sách Bác Sĩ</h3>
          </div> -->
          <div class="col-sm-12">
          <div class="row justify-content-lg-between">
            <div class="col-12 col-lg-auto mb-1 ml-3">
              <div class="d-flex">
                <input class="form-control mr-sm-2 bg-white" type="search" placeholder="Search" aria-label="Search" v-model="search" @keyup.enter="getDoctors">
                <button class="btn bg-pri bd-pri text-white my-2 my-sm-0" @click="getDoctors">Tìm kiếm</button>
              </div>
            </div>
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex h-100">
                <button class="btn bg-pri bd-pri text-white mr-3 btn-icon-add-sm h-100" @click="modalAdd(true)">Thêm bác sĩ</button>
              </div>
            </div>
          </div>
        </div>
          <div class="p-3">
            <DoctorTable 
              v-if="doctors" 
              :doctors="doctors" 
              :is-admin="true" 
              @onLoginDoctor="onLoginDoctor" 
              @onResetPassword="onResetPassword"
              @onWelcome="onWelcome" 
              @onActive="onActive"/>
            <Pagination v-if="doctors" :items="doctors" :current_page="current_page" @onRefresh="getDoctors" ></Pagination>
          </div>
        </div>
      </div>
    </div>
        <div class="modal fade" id="modal__add" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Tạo tài khoản</h5>
        <button type="button" class="close bg-pri bd-pri text-white btn" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="robo-24-500">&times;</span>
        </button>
                    </div>
                    <div class="modal-body">
                        
                <div>
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="robo-20-500 text-black mb-2 required">Họ và tên</label>
                            </div>
                            <div class="col-sm-6 text-right">
                                <span class="robo-14-400 text--red">{{error.name}}</span>
                            </div>
                        </div>
                        <div class="input-group-login input-group" :class="error.name ? 'error-form-input-group' : ''">
                            <input @click="error.name=''" @input="error.name=''" v-model="form_doctor_sign_up.name" type="text" class="form-control form-input-login bg-white" placeholder="Nhập họ và tên">
                            <span class="input-group-text bg-white border-0"><i v-show="error.name" class="fa fa-exclamation-circle font-size-20 mr-2 text--red"></i></span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="robo-20-500 text-black mb-2 required">Email</label>
                            </div>
                            <div class="col-sm-6 text-right">
                                <span class="robo-14-400 text--red">{{error.email}}</span>
                            </div>
                        </div>
                        <div class="input-group-login input-group" :class="error.email ? 'error-form-input-group' : ''">
                            <input @click="error.email=''" @input="error.email=''" v-model="form_doctor_sign_up.email" type="email" class="form-control form-input-login bg-white" placeholder="Nhập email">
                            <span class="input-group-text bg-white border-0"><i v-show="error.email" class="fa fa-exclamation-circle font-size-20 mr-2 text--red"></i></span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="robo-20-500 text-black mb-2 required">Mật khẩu</label>
                            </div>
                            <div class="col-sm-6 text-right">
                                <span class="robo-14-400 text--red">{{error.password}}</span>
                            </div>
                        </div>
                        <div class="input-group-login input-group" :class="error.password ? 'error-form-input-group' : show_pw && !error.password ? 'show-pw-form-input' : ''">
                            <input @click="error.password=''" @input="error.password=''" v-model="form_doctor_sign_up.password" :type="!show_pw ? 'password' : 'text'" class="form-control form-input-login bg-white" placeholder="Nhập mật khẩu"  autocomplete="new-password">
                            <span v-if="error.password" class="input-group-text bg-white border-0"><i class="fa fa-exclamation-circle font-size-20 mr-2 text--red"></i></span>
                            <span @click="show_pw = !show_pw" v-if="!error.password" class="input-group-text bg-white border-0 cursor-pointer mr-2"><i :class="!show_pw ? 'font-size-20 text-muted fa fa-eye' : 'font-size-20 text-white fa fa-eye-slash'"></i></span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="robo-20-500 text-black mb-2 required">Nhập lại mật khẩu</label>
                            </div>
                            <div class="col-sm-6 text-right">
                                <span class="robo-14-400 text--red">{{error.repass}}</span>
                            </div>
                        </div>
                        <div class="input-group-login input-group" :class="error.repass ? 'error-form-input-group' : show_pw_confirm && !error.repass ? 'show-pw-form-input' : ''">
                            <input @click="error.repass=''" @input="error.repass=''" v-model="form_doctor_sign_up.repass" :type="!show_pw_confirm ? 'password' : 'text'" class="form-control form-input-login bg-white" placeholder="Nhập lại mật khẩu"  autocomplete="new-password">
                            <span v-if="error.repass" class="input-group-text bg-white border-0"><i class="fa fa-exclamation-circle font-size-20 mr-2 text--red"></i></span>
                            <span @click="show_pw_confirm = !show_pw_confirm" v-if="!error.repass" class="input-group-text bg-white border-0 cursor-pointer mr-2"><i :class="!show_pw_confirm ? 'font-size-20 text-muted fa fa-eye' : 'font-size-20 text-white fa fa-eye-slash'"></i></span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="robo-20-500 text-black mb-2 required">Số điện thoại</label>
                            </div>
                            <div class="col-sm-6 text-right">
                                <span class="robo-14-400 text--red">{{error.phone}}</span>
                            </div>
                        </div>
                        <div class="input-group-login input-group" :class="error.phone ? 'error-form-input-group' : ''">
                            <input @click="error.phone=''" @input="error.phone=''" v-model="form_doctor_sign_up.phone" type="text" class="form-control form-input-login bg-white" placeholder="Nhập số điện thoại">
                            <span  class="input-group-text bg-white border-0"><i v-show="error.phone" class="fa fa-exclamation-circle font-size-20 mr-2 text--red"></i></span>
                        </div>
                    </div>
                    <!-- <div class="mb-3">
                      <div class="d-inline">
                       <input type="radio"> 
                      </div>
                      <div class="d-inline robo-16-400">Tạo tài khoản có nghĩa là bạn đã đồng ý với <span class="txt-pri cursor-pointer decoration-under">Điều khoản dịch vụ</span>, <span class="txt-pri cursor-pointer decoration-under">Chính sách quền riêng tư</span> và cài đặt thông báo mặc định của chúng tôi</div>
                    </div> -->
                    <div class="mb-3 text-right">
                        <button type="button" @click="onSignUp()" class="btn-l bg-pri bd-pri text-white">Tạo tài khoản</button>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal__success" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Thành công</h5>
                    </div>
                    <div class="modal-body">
                        <div align="center">
                            <img src="../../../public/assets/images/icon/icon_account_verification.png">
                            <p style="font-weight: bold;">Tạo tài khoản thành công</p>
                            <p>Một email xác nhận đã được gửi đến:</p>
                            <p style="font-weight: bold;">{{email_registed}}</p>
                        </div>
                        <div class="form-footer text-center">
                            <p class="message-sign-up" style="color: #7386C7;"><a href="javascript:void(0)" @click="onSendEmail()" v-if="able_resend">Gửi lại email xác nhận</a> <br><span v-if="!able_resend" style="color: red;">Gửi lại còn {{millisToMinutesAndSeconds(set_seconds)}}</span></p>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-3">
                                <button type="button" class="btn bg-pri bd-pri text-white" @click="modalAdd()">Đóng</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
  import appUtils from '../../utils/appUtils'
  import DoctorTable from '../../components/DoctorTable'
  import Pagination from '../../components/Pagination'
  export default {
    components: {Pagination, DoctorTable},
    data () {
      return {
        doctors: null,
        current_page: 1,
        search: '',
        form_doctor_sign_up: {
          email: '',
          password: '',
          repass: '',
          name: '',
          phone: '',
          username: ''
        },
        error: {
          email: '',
          password: '',
          repass: '',
          name: '',
          phone: '',
          username: ''
        },
        show_pw: false,
        show_pw_confirm: false,
        able_resend: true,
        set_seconds: 59000,
        refresh_resend: null,
        email_registed: ''
      }
    },
    mounted () {
      let self = this

      self.getDoctors()
    },
    methods: {
      async onSignUp () {
        let self = this
        if (!self.form_doctor_sign_up.name) {
          self.error.name = 'Vui lòng nhập họ và tên!'
          return false
        } else if (!self.form_doctor_sign_up.email) {
          self.error.email = 'Vui lòng nhập email!'
          return false
        } else if (!self.form_doctor_sign_up.password) {
          self.error.password = 'Vui lòng nhập mật khẩu!'
          return false
        } else if (!self.checkPass(self.form_doctor_sign_up.password)) {
          self.error.password = 'Mật khẩu của bạn phải có 6-12 ký tự bao gồm chữ và số!'
          return false
        } else if (!self.form_doctor_sign_up.repass) {
          self.error.repass = 'Vui lòng nhập lại mật khẩu!'
          return false
        } else if (self.form_doctor_sign_up.repass !== self.form_doctor_sign_up.password) {
          self.error.repass = 'Nhập lại mật khẩu không đúng!'
          return false
        } else if (!self.form_doctor_sign_up.phone) {
          self.error.phone = 'Vui lòng nhập số điện thoại!'
          return false
        } else {
          try {
            self.loading = true
            self.form_doctor_sign_up.code = self.code ? self.code : ''
            self.form_doctor_sign_up.username = self.form_doctor_sign_up.email
            let resp = await self.$rf.getRequest('AuthRequest').postDoctorRegister(self.form_doctor_sign_up)
            if (resp) {
              self.getDoctors()
              self.email_registed = self.form_doctor_sign_up.email
              self.modalAdd(false)
              self.modalScs(true)
              self.form_doctor_sign_up = {
                email: '',
                password: '',
                repass: '',
                name: '',
                phone: '',
                username: ''
              }
            }
          } catch (e) {
            self.loading = false
          }
        }
      },
      checkPass (str) {
        if (str.length >= 6) {
          var result = str.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*?_-]{6,12}$/) ? str.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*?_-]{6,12}$/).toString() : ''
          if (result === str) {
            return true
          }
          return false
        }
        return false
      },
      async getDoctors () {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        let params = {
          page: self.current_page
        }
        if (self.search) {
          params.search = self.search
        }

        let resp = await self.$rf.getRequest('AdminRequest').getDoctors(params)
        self.doctors = resp.data
      },
      async onLoginDoctor (doctor_id) {
        let self = this
        var r = confirm('Copy ID')
        if (!r) {
          return false
        }

        self.loading = true
        if (process.env.NODE_ENV !== 'development') {
          window.localStorage.isAdmin = true
        }
  
        let resp = await self.$rf.getRequest('AdminRequest').loginDoctor(doctor_id)
        if (resp.data.access_token) {
          appUtils.removeLocalToken()
          appUtils.removeLocalUser()

          appUtils.setLocalToken(resp.data.access_token)
          resp = await self.$rf.getRequest('AuthRequest').me()
          if (resp) {
            appUtils.setLocalUser(resp.data)
          }

          self.loading = false

          setTimeout(() => {
            window.location.href = '/'
          }, 300)
        }
      },
      async onWelcome (doctor) {
        let self = this
        var r = confirm('Do you want to reset password this doctor account?')
        if (r) {
          try {
            var params = {
              doctor_id: doctor.id,
              clinic_id: doctor.clinicId
            }
            await self.$rf.getRequest('AdminRequest').welcomePassword(params)
            this.$toast.clear()
            this.$toast.open({
              message: 'Đổi mật khẩu thành công',
              type: 'success'
            })
            // console.log(resp)
          } catch (e) {
            console.log(e)
          }
        }
      },
      async onResetPassword (id) {
        let self = this
        var r = confirm('Do you want to reset password this doctor account?')
        if (r) {
          try {
            var params = {
              password: 'Hodo@123456',
              password_confirmation: 'Hodo@123456'
            }
            await self.$rf.getRequest('AdminRequest').postResetPassword(id, params)
            this.$toast.clear()
            this.$toast.open({
              message: 'Đổi mật khẩu thành công',
              type: 'success'
            })
            // console.log(resp)
          } catch (e) {
            console.log(e)
          }
        }
      },
      async onActive (id, status) {
        let self = this
        try {
          var params = {}
          var str_mess = ''
          if (status === 0) {
            params.status = 1
            str_mess = 'Mở tài khoản thành công'
          } else if (status === 1) {
            params.status = 0
            str_mess = 'Khoá tài khoản thành công'
          }
          await self.$rf.getRequest('AdminRequest').postDoctorActive(id, params)
          this.$toast.clear()
          this.$toast.open({
            message: str_mess,
            type: 'success'
          })
          self.getDoctors()
        } catch (e) {
          console.log(e)
        }
      },
      async onSendEmail () {
        let self = this
        try {
          self.resend_email.email = self.form_doctor_sign_up.email
          await self.$rf.getRequest('AuthRequest').postResendEmail(self.resend_email)
          self.able_resend = false
          self.refresh_resend = setInterval(self.count_the_number, 1000)
        } catch (e) {}
      },
      count_the_number () {
        let self = this
        self.set_seconds = self.set_seconds - 1000
        if (self.set_seconds === 0) {
          clearInterval(self.refresh_resend)
          self.set_seconds = 59000
          self.able_resend = true
        }
      },
      millisToMinutesAndSeconds (millis) {
        var minutes = '0'
        var seconds = Math.floor(millis / 1000)
        return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
      },
      modalAdd (show) {
        window.$('#modal__add').modal(show ? 'show' : 'hide')
      },
      modalScs (show) {
        window.$('#modal__success').modal(show ? 'show' : 'hide')
      }
    }
  }
</script>
